import {MediaType} from "../../../../jordan/shared";
import {ABSOLUTE_FILL} from "../../../../core/animations/fast-lane-2/helpers";
import Media from "../../../../jordan/components/Media";
import {CSSProperties, useMemo} from "react";
import {usePx} from "./ScreenWrapper";
import {motion} from "framer-motion";
import Jumpman from "../../../../core/animations/fast-lane-2/components/icons/jumpman";
import {StatusType} from "./Status";

interface TitleProps {
    supertitle?: string;
    title?: string;
    caps: boolean;
    media: Array<MediaType>;
    currentMonth?: string;
    currentDate?: string;
    theme: string;
    status: StatusType | string;
}

export default function Title({supertitle, title, caps, theme, status, media, currentMonth, currentDate}: TitleProps) {
    const px = usePx();
    const hasMedia = useMemo(() => {
        return media?.filter(i => i.url)?.length > 0;
    }, [media]);

    const showMedia = useMemo(() => {
        const hasTitle = title?.trim()?.length > 0;

        return !hasTitle && hasMedia;
    }, [hasMedia, title]);

    const fontSize = useMemo(() => {
        if (title?.length > 45) {
            return 110;
        }

        if (title?.length > 30) {
            return 135;
        }

        if (title?.length > 20) {
            return 150;
        }

        return 190;
    }, [title]);

    const fontTheme: CSSProperties = useMemo(() => {
        if (theme?.indexOf('well') === 0) {
            return {
                fontFamily: 'Brut Grotesque',
                textTransform: caps ? 'uppercase' : 'lowercase',
                letterSpacing: '-0.04em',
                lineHeight: '83%',
            };
        }

        return {
            fontFamily: 'NIKE_FUTURA',
            textTransform: 'uppercase',
            lineHeight: '83%',
        };
    }, [theme, caps]);

    const isWof = useMemo(() => theme?.indexOf('wof') === 0, [theme]);
    const showAlert = status === 'do_not_enter' || status === 'booked';

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            // marginLeft: px(20),
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            position: 'relative',
        }}>
            {
                showMedia && (
                    <Media
                        transparent
                        style={{...ABSOLUTE_FILL, zIndex: 0}}
                        media={media}
                        width={1080}
                        height={1920}
                        preventInitial
                    />
                )
            }

            <motion.div
                animate={{
                    opacity: showMedia ? 0 : 1,
                }}
            >
                {
                    isWof && (
                        <div style={{width: px(100), margin: `${px(46)}px auto`}}>
                            <Jumpman/>
                        </div>
                    )
                }
                <div style={{
                    fontSize: px(36),
                    marginBottom: px(25),
                    lineHeight: px(2),
                    whiteSpace: 'pre-line',
                }}>
                    {supertitle}
                </div>
                <div
                    style={{
                        ...fontTheme,
                        // respect line breaks
                        whiteSpace: 'pre-line',
                        fontSize: px(fontSize),
                    }}
                >
                    {title}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: px(56)}}>
                    <motion.div
                        style={{
                            opacity: showAlert ? 1 : 0,
                            background: '#ff0000',
                            borderRadius: px(10),
                            padding: `${px(24)}px ${px(40)}px`,
                            fontSize: px(24),
                            fontWeight: '500',
                        }}
                    >
                        Do Not Enter
                    </motion.div>
                </div>
            </motion.div>
        </div>
    )
}

import { extractData } from "../../utilities";
import { extendWithGlobalWrapIdx } from "../helpers";
import { loadStoreChannels } from "../../api";
import { get, isEmpty, keyBy } from "lodash";
import { useState } from "react";

const TICKERS_DEMO = {
  screen: {
    // width: 1400,
  },
  sections: [
    {
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
    {
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_women_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
    {
      transform: {
        scaleY: .3,
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_women_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
    {
      transform: {
        rotate: '180deg',
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_men_1111',
          width: 1000,
          height: 250,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=ll_ticker_women_1111',
          width: 1000,
          height: 250,
        }
      ]
    },
  ]
};

const COVER_STORY_DEMO = {
  screen: {},
  sections: [
    {
      transform: {
        scaleY: 0.5,
        y: '-25%',
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=cover_story_men_1111&demo=pixel-map',
          width: 1620,
          height: 1080,
        }
      ]
    },
    {
      transform: {
        scaleY: 0.5,
        y: '-75%',
      },
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=cover_story_women_1111&demo=pixel-map',
          width: 1620,
          height: 1080,
        }
      ]
    }
  ]
};

const SERVICE_HUB = {
  screen: {
    width: 1920,
    height: 1080,
  },
  transform: {
    // scaleY: '50%',
    transformOrigin: '0 0'
  },
  sections: [
    {
      wrap: [
        {
          type: 'iframe',
          url: '/touchpoint/?channel=tickers_mall_entrance_kr&origin=pixel-map&width=768&height=48',
          width: 768,
          height: 48,
        }
      ]
    },
    {
      wrap: [
        {
          type: 'spacer',
          width: 1008,
          height: 48,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=tickers_mall_entrance_kr&origin=pixel-map&width=3696&height=48',
          width: 3696,
          height: 48,
        }
      ],
    },
    {
      wrap: [
        {
          type: 'spacer',
          width: 720,
          height: 48,
        },
        {
          type: 'iframe',
          url: '/touchpoint/?channel=tickers_mall_entrance_kr&origin=pixel-map&width=3504&height=48',
          width: 3504,
          height: 48,
        }
      ],
    },
  ]
};

const usePixelMapContent = () => {
  const [storeChannelsMap, setStoreChannelsMap] = useState({});

  const loadStoreChannelsMap = async (pixelMapStoreId) => {
    if (!pixelMapStoreId) {
      return;
    }

    const storeChannels = await loadStoreChannels(pixelMapStoreId);
    setStoreChannelsMap(keyBy(storeChannels, 'slug'));
  }

  const getPixelMapStaticContent = (contentType, params) => {
    const {
      lodef = false,
    } = params;

    const staticContentGenerator = {
      tickers_extreme: () => ([...TICKERS_DEMO, ...TICKERS_DEMO]),
      tickers: () => TICKERS_DEMO,
      cover_story: () => COVER_STORY_DEMO,
      'service-hub': () => SERVICE_HUB,
      data: () => {
        const extractedData = extractData();
        const storeChannelsMap = get(extractedData, 'store_channels_map', null);

        if (lodef && !isEmpty(storeChannelsMap)) {
          setStoreChannelsMap(storeChannelsMap);
        }
        return extendWithGlobalWrapIdx(extractedData);
      },
    }[contentType] ?? null;

    if (staticContentGenerator && typeof staticContentGenerator === 'function') {
      return staticContentGenerator();
    }

    return null;
  }

  return {
    storeChannelsMap,
    loadStoreChannelsMap,
    getPixelMapStaticContent,
  }
}

export default usePixelMapContent;

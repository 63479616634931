import { RefObject, useEffect, useState } from "react";

const FONT_SIZE_LIMITS = {
    MIN_CALCULATED:  6,
    MIN: 6,
    MAX_CALCULATED: 100,
    MAX: 64,
}

const DEFAULT_SLOT_CONFIG = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    fontSizes: {
        secondaryLabel: 16,
        mainLabel: 18,
    }
};

function usePixelMapSlot(slotRef: RefObject<HTMLDivElement>) {
    const [slotConfig, setSlotConfig] = useState(DEFAULT_SLOT_CONFIG);

    const calculateFontSize = (width: number, height: number, percentage: number) => {
        let fontSize = Math.min(width, height) * percentage;

        const aspectRatio = width / height;

        fontSize *= aspectRatio > 1 ? 1.2 : 0.5;

        if (fontSize < FONT_SIZE_LIMITS.MIN_CALCULATED) {
            fontSize = FONT_SIZE_LIMITS.MIN;
        }

        if (fontSize > FONT_SIZE_LIMITS.MAX_CALCULATED) {
            fontSize = FONT_SIZE_LIMITS.MAX;
        }

        return fontSize;
    };

    const prepareSlotConfig = () => {
        if (!slotRef.current) return;

        const rect = slotRef.current.getBoundingClientRect();

        const height = Math.ceil(Math.max(0,
            Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0)
        ));

        const width = Math.ceil(Math.max(0,
            Math.min(rect.right, window.innerWidth) - Math.max(rect.left, 0)
        ));

        const x = Math.max(0, rect.left);
        const y = Math.max(0, rect.top);

        const fontSizes = {
            secondaryLabel: calculateFontSize(width, height, 0.03),
            mainLabel: calculateFontSize(width, height, 0.1),
        };
        setSlotConfig({ width, height, x, y, fontSizes });
    };

    useEffect(() => {
        prepareSlotConfig();
    }, []);

    return {
        slotConfig,
    };
}

export default usePixelMapSlot;

import axios from "axios";
import { DATA_BASE_URL, ENABLED_DISPLAYS_LOCAL_STORAGE_CACHE, VERSION } from "./config";
import { isEmpty, isNumber } from "lodash";
import * as AppEventsService from './services/app-events.service';
import DataEventsEnum from './enums/DataEvents.enum';


/**
 *
 * @param {string} slug
 * @returns {Promise<*|null>}
 */
export const pixelMap = async (slug) => {
	const getPixelMapCallback = async () => await request({
		baseURL: DATA_BASE_URL,
		url: `api/public/pixel-maps/${slug}`
	});

	return cachingWrapper({
		requestCallback: getPixelMapCallback,
		storageKey: `pixel-map-${slug}`
	});
}

/**
 *
 * @param {number} storeId
 * @returns {Promise<[]>}
 */
export const loadStoreChannels = async (storeId) => {
	return request({
		baseURL: DATA_BASE_URL,
		url: `api/public/rise/store/${storeId}/channels`
	});
}

/**
 * Wrapper for making a request on api (with axios)
 * @param {Object} config
 * @param {string} config.baseURL
 * @param {string} config.url
 * @param {Object} config.params={}
 * @param {Object} config.params.headers={}
 * @returns {Promise<*>}
 */
export const request = async (config = {}) => {
	const {
		baseURL,
		url,
		params = {}
	} = config;
	const { headers = {} } = params;

	try {
		const response = await axios({
			baseURL,
			url,
			headers,
			...params
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

const shouldCleanStorageValue = (statusCode = null) => {
	if (!isNumber(statusCode)) {
		return false;
	}

	const isServerError = statusCode?.toString()[0] === '5';
	return !isServerError;
};

/**
 *
 * @param {Object} config
 * @param {function} config.requestCallback
 * @param {string} [config.storageKey]
 * @returns {Promise<null|any>}
 */
export const cachingWrapper = async (config) => {
	const {
		requestCallback = async () => null,
		storageKey = ''
	} = config;
	try {
		AppEventsService.sendEvent(DataEventsEnum.Start);

		const result = await requestCallback();
		if (storageKey && ENABLED_DISPLAYS_LOCAL_STORAGE_CACHE) {
			localStorage.setItem(storageKey, JSON.stringify(result));
		}

		AppEventsService.sendEvent(DataEventsEnum.Loaded);

		return result;
	} catch (error) {
		if (shouldCleanStorageValue(error.response?.status) || shouldCleanStorageValue(error.response?.data?.statusCode)) {
			AppEventsService.sendEvent(DataEventsEnum.Error, error);
			localStorage.removeItem(storageKey);
			throw error;
		}

		const cached = JSON.parse(localStorage.getItem(storageKey));

		if (!isEmpty(cached)) {
			AppEventsService.sendEvent(DataEventsEnum.LoadCache);
			return cached;
		}

		AppEventsService.sendEvent(DataEventsEnum.Error, error);
		throw error;
	}
}

/**
 *
 * @param {Object} config
 * @param {string} config.baseURL
 * @param {number} config.animationId
 * @param {string} config.version=VERSION
 * @returns {Promise<*|null>}
 */
export const getAnimation = async (config = {}) => {
	const {
		baseURL,
		animationId,
		version = VERSION
	} = config;

	const getAnimationCallback = async () => await request({
		baseURL,
		url: `/api/public/animation/${animationId}`,
		params: {
			params: { v: version }
		}
	});

	return cachingWrapper({
		requestCallback: getAnimationCallback,
		storageKey: `animation-${animationId}`
	});
}

/**
 *
 * @param {Object} config
 * @param {string} config.baseURL
 * @param {number} config.storeId
 * @param {number} config.zoneId
 * @param {number} config.displayId
 * @param {string} config.version
 * @returns {Promise<*|null>}
 */
export const getScreenConfig = async (config = {}) => {
	const {
		baseURL,
		storeId,
		zoneId,
		displayId,
		version = VERSION
	} = config;

	const getScreenConfigCallback = async () => await request({
		baseURL,
		url: `api/public/store/${storeId}/zone/${zoneId}/display/${displayId}`,
		params: {
			params: { v: version }
		}
	});

	return cachingWrapper({
		requestCallback: getScreenConfigCallback,
		storageKey: generateScreenConfigStorageKey({ displayId })
	});
}

/**
 *
 * @param {Object} config
 * @param {string} config.baseURL
 * @param {number} config.displayId
 * @param {string} config.version=VERSION
 * @returns {Promise<*|null>}
 */
export const getScreenConfigByDisplayId = async (config = {}) => {
	const {
		baseURL,
		displayId,
		version = VERSION
	} = config;

	const getScreenConfigCallback = async () => await request({
		baseURL,
		url: `api/public/display/${displayId}`,
		params: {
			params: { v: version }
		}
	});

	return cachingWrapper({
		requestCallback: getScreenConfigCallback,
		storageKey: generateScreenConfigStorageKey({ displayId })
	});
}

export const getCheer = async (config = {}) => {
	const {
		baseURL,
		productStyleId,
	} = config;

	const getCheerCallback = async () => await request({
		baseURL,
		url: `api/public/cheer/${productStyleId}`
	});

	return cachingWrapper({
		requestCallback: getCheerCallback,
		storageKey: `cheer-${productStyleId}`
	});
}

/**
 *
 * @param {Object} params
 * @param {string} params.displayId
 * @returns {`screen-config-${string}` || ''}
 */
export const generateScreenConfigStorageKey = (params = {}) => {
	const { displayId } = params;

	if (displayId) {
		return `screen-config-${displayId}`;
	}

	return '';
}

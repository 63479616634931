import axios from 'axios';

const baseURL =
  'https://api.airtable.com/v0/appcUTSVuf734QvBw/tblU6bSB98J4U5xxX?returnFieldsByFieldId=true';
const testURL =
  'https://api.airtable.com/v0/appUxS2CTelmO3fGg/tblCJa2iy7XnEihCH?returnFieldsByFieldId=true';

export async function fetchEvents(offset, testing = false) {
  const url = testing === 'true' ? testURL : baseURL;

  return new Promise((resolve, reject) => {
    var config = {
      method: 'get',
      url: offset ? `${url}&offset=${offset}` : url,
      headers: {
        Authorization:
          'Bearer patT6I2iU5OKx5GPX.e9db2fee64fe005d2db9f828a7edcd7bb53da2668670699013e29ba2997153f6',
      },
    };

    axios(config)
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

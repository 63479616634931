import React, { useEffect, useMemo, useRef, useState } from 'react';
import PixelMap from "../PixelMap";
import WrappableElement from "../WrappableElement";
import './reset.scss';
import { pixelMap } from "../../api";
import _, { get } from "lodash";
import * as AppEventsService from '../../services/app-events.service'
import PixelMapEventsEnum from '../../enums/PixelMapEvents.enum';
import Overlay from "../../touchpoints/core/animations/overlay/Overlay";
import PixelMapChannelSlot from "../PixelMapChannelSlot";
import PixelMapDisplaySlot from '../PixelMapDisplaySlot';
import {
  ANALYTICS_BASE_URL,
  DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY,
  IS_ANALYTICS_ENABLED,
  SEND_HEARTBEAT_INTERVAL_SECONDS,
  HEARTBEATS_DELAY,
} from '../../config';
import { PixelMapVitals } from 'vitals';
import { DateTime } from "luxon";
import PixelMapTestModeSlot from "../PixelMapTestModeSlot";
import PIXEL_MAP_WRAP_TYPE from "../../constants/PixelMapWrapType";
import {
  extendWithGlobalWrapIdx,
  getMinSectionSide,
  isWrapValid,
  mapSectionsWrap
} from "../helpers";
import usePixelMapContent from "./usePixelMapContent.hook";

function query(item, content) {
  let q = '';

  if (item?.query && item.query !== '') {
    q += `&${item?.query}`;
  }

  if (content?.query && content.query !== '') {
    q += `&${content?.query}`;
  }

  return q;
}

const DemoPixelMap = () => {
  const params = new URLSearchParams(window.location.search);
  const [content, setContent] = useState(null);
  const [isLodef, setLodef] = useState(false);
  const lastUpdated = useRef(null);
  const intervalRef = useRef(null);

  const {
    storeChannelsMap,
    loadStoreChannelsMap,
    getPixelMapStaticContent,
  } = usePixelMapContent();

  useEffect(() => {
    AppEventsService.sendEvent(PixelMapEventsEnum.Init);

    const slug = params.get('pixelmap');
    const lodef = params?.get('lodef') === 'true';
    setLodef(lodef);

    const staticContent = getPixelMapStaticContent(slug, { lodef });
    if (staticContent) {
      return setContent(staticContent);
    }

    pixelMap(slug).then(async (pixelMapPayload) => {
      if (lodef) {
        await loadStoreChannelsMap(pixelMapPayload?.store_id);
      }

      AppEventsService.sendEvent(PixelMapEventsEnum.DataLoaded);

      const {
        json_data,
        updated_at,
      } = pixelMapPayload;

      setContent(extendWithGlobalWrapIdx(json_data));
      lastUpdated.current = updated_at;
      checkForUpdates(slug);

      const shouldInitializeHeartbeatsSending = !lodef && !isPreviewMode && !isHeartbeatsSendingDisabled;

      if (IS_ANALYTICS_ENABLED && shouldInitializeHeartbeatsSending) {
        startSendHeartbeats(pixelMapPayload);
      }
    });

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const startSendHeartbeats = (pixelMapPayload) => {
    const pixelMapSlug = params.get('pixelmap');

    const vitals = new PixelMapVitals({
        baseUrl: ANALYTICS_BASE_URL,
        slug: pixelMapSlug,
        label: pixelMapSlug,
        storeId: _.get(pixelMapPayload, 'store_id'),
        delay: HEARTBEATS_DELAY,
    });

    intervalRef.current = setInterval( () => vitals.sendHeartbeat(), SEND_HEARTBEAT_INTERVAL_SECONDS);
  }

  const isPreviewMode = useMemo(() => {
    return params.get('mode') === 'preview';
  }, [params]);

  const isHeartbeatsSendingDisabled = useMemo(() => {
    return params.get(DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY) === 'true';
  }, [params]);

  const isEnabledTestMode = useMemo(() => {
    const testModeEnabledTo = get(content, 'screen.test_mode_enabled_to', null);

    if (!testModeEnabledTo) {
      return false;
    }

    const currentUTCDate = DateTime.local({ zone: 'utc' }).toMillis();
    const testModeEnabledToDate = DateTime.fromISO(testModeEnabledTo, { zone: 'utc' }).toMillis();
    return currentUTCDate <= testModeEnabledToDate;
  }, [content]);

  const mappedSectionsWrap = useMemo(() => mapSectionsWrap(content), [content])

  const isConfigured = useMemo(() => mappedSectionsWrap.some(isWrapValid), [mappedSectionsWrap]);
  const minWrapSide = useMemo(() => {
      return getMinSectionSide(isEnabledTestMode ? mappedSectionsWrap : []);
  }, [mappedSectionsWrap]);

  /**
   * Will check every minute for changes.
   *
   * @param slug
   */
  const checkForUpdates = (slug) => {
    const i = setInterval(() => {
        pixelMap((slug)).then(({updated_at}) => {
          AppEventsService.sendEvent(PixelMapEventsEnum.DataLoaded);

          if (updated_at !== lastUpdated.current) {
            clearInterval(i);
            location.reload();
          }
        }).catch((err) => {
          AppEventsService.sendEvent(PixelMapEventsEnum.DataError);
          console.warn('could not load pixelmap', err)
        })

    }, 60_000);
  };

  const groupHeight = (group) => {
    return group.reduce((acc, item) => {
      return Math.max(acc, item?.height ?? 200);
    }, 0)
  }

  const groupWidth = (group) => {
    return group.reduce((acc, item) => {
      return acc + item?.width ?? window.innerWidth;
    }, 0)
  }

  const wrapsInternally = (group) => {
    const isInternalWrapping = (query) => query?.indexOf('wrapping-style=internal') >= 0

    return group.filter((item) => {
      return isInternalWrapping(item.query);
    }).length === 1;
  }

  const getTotalCount = () => {
    return content?.sections.reduce((acc, curr) => {
      return acc + curr?.wrap?.filter(a => a.type === 'iframe')?.length ?? 0;
    }, 0);
  }

  const groupTransform = (group) => {
    return {
      ...group?.transform ?? {},
    };
  }

  const generateChannelUrl = (item, urlParams) => {
	  const pixelMapSlug = params.get('pixelmap');

	  const {
		  baseQuery,
		  index,
		  total,
	  } = urlParams;

	  const searchParams = new URLSearchParams({
		  channel: item.channel,
		  origin: 'pixel-map',
		  label: `pixel-map-${pixelMapSlug}`,
		  'pixelmap-slug': pixelMapSlug,
		  width: item.width,
		  'screen-width': item?.screenwidth ?? content?.screen?.width,
		  height: item.height,
		  index,
		  total
	  });

	  if (isPreviewMode || isHeartbeatsSendingDisabled) {
		  searchParams.set(DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY, 'true');
	  }

    const prefix = window.location.origin + window.location.pathname;

	  return `${prefix}?${searchParams.toString()}${baseQuery}`;
  }

  const renderItem = (wrapItemParams, groupParams) => {
    const { wrapItem, wrapItemIdx } = wrapItemParams;
    const { groupIdx, group } = groupParams;

    const props = {
      key: `wrap_${groupIdx}_${wrapItemIdx}`,
      width: wrapItem.width,
      height: wrapItem.height,
    }

    if (wrapItem.type === 'spacer') {
      const {key, ...style} = props;
      return (
        <div
          key={key}
          style={{
            ...style
          }}
        />
      );
    }

    if (!wrapItem?.url && !wrapItem?.channel && wrapItem?.type !== PIXEL_MAP_WRAP_TYPE.DISPLAY) {
      return null;
    }

    const { width, height, ...otherProps } = props;

    if (isEnabledTestMode) {
      return (
        <PixelMapTestModeSlot
          idx={groupIdx}
          key={props.key}
          width={width}
          height={height}
          minWrapSide={minWrapSide}
          {...otherProps}
        />
      )
    }

    if (isLodef || !wrapItem.channel) {
      return wrapItem.type === PIXEL_MAP_WRAP_TYPE.DISPLAY ? (
        <PixelMapDisplaySlot
          key={props.key}
          width={width}
          height={height}
          position={wrapItem.position}
          areas={wrapItem.areas}
        />
      ) : (
        <PixelMapChannelSlot
          {...otherProps}
          width={width}
          height={height}
          channelSlug={wrapItem.channel}
          globalIdx={wrapItem.globalIdx}
          storeChannelsMap={storeChannelsMap}
          groupWrapLength={get(group, 'wrap', []).length}
        />
      );
    }

    const initialIndex = Number(content?.screen?.initial_index ?? 0);
    const index = wrapItem + initialIndex;
    const total = getTotalCount() + initialIndex;

    const url = wrapItem.hasOwnProperty('url')
      ? wrapItem.url
      : generateChannelUrl(wrapItem, { baseQuery: query(wrapItem, content), index, total });

    return (
      <iframe
        {...props}
        frameBorder="0"
        src={url}
      />
    );
  }

  if (!content) {
    return (
      <div>Loading...</div>
    );
  }

  if (!isConfigured) {
      return <Overlay />;
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
      }}
    >
      <PixelMap
        config={content?.screen}
        timingFunction={content?.timing_function}
        transform={content?.transform}
        testMode={isLodef || isEnabledTestMode}
        backgroundColor={isLodef ? '#969c9e' : isEnabledTestMode ? '#444' : null}
      >
        {
          content && content?.sections && content.sections?.length > 0 && content.sections.filter(({wrap}) => wrap).filter(({wrap}) => groupWidth(wrap) > 0).map((group, groupIdx) => (
            <WrappableElement
              testOff={!isLodef && !isEnabledTestMode}
              key={`wrap_${groupIdx}`}
              transform={groupTransform(group)}
              width={groupWidth(group.wrap)}
              height={groupHeight(group.wrap)}
              wrapsInternally={wrapsInternally(group.wrap)}
              sectionWidth={group.section_width}
            >
              {
                group.wrap.map((wrapItem, wrapItemIdx) => renderItem(
                  { wrapItem, wrapItemIdx },
                  { groupIdx, group }
                ))
              }
            </WrappableElement>
          ))
        }
      </PixelMap>
    </div>
  );
};

export default DemoPixelMap;

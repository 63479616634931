import styles from './PixelMapChannelSlot.module.scss';
import React, { useMemo, useRef } from 'react';
import { getColor } from "../utils";
import { get, isNumber } from "lodash";
import usePixelMapSlot from "./usePixelMapSlot.hook";
import { SLOT_RENDER_MODE } from "./PixelMapChannelSlot.constants";

interface PixelMapChannelSlotProps {
    leftOffset: number,
    channelIdx: number,
    width: number,
    height: number,
    channelSlug: string,
    groupWrapLength: number,
    wrapRowIdx: number,
    wrapCount: number,
    globalIdx: number,
    storeChannelsMap: { title: string }[],
}

const PixelMapChannelSlot = (props: PixelMapChannelSlotProps) => {
    const {
        leftOffset,
        channelIdx,
        width,
        height,
        channelSlug,
        groupWrapLength,
        wrapRowIdx,
        wrapCount,
        globalIdx,
        storeChannelsMap = {},
        ...otherProps
    } = props;
    const slotRef = useRef(null);
    const { slotConfig } = usePixelMapSlot(slotRef);

    const shouldRenderWrapLabel = useMemo(() => {
        return isNumber(wrapRowIdx) && isNumber(wrapCount) && wrapCount > 1;
    }, [wrapRowIdx, wrapCount]);

    const slotContentOrientation = useMemo(() => {
        const aspectRatio = slotConfig.width / slotConfig.height;
        const verticalThreshold = 0.3;

        if (aspectRatio < verticalThreshold) {
            return SLOT_RENDER_MODE.PORTRAIT;
        }

        return SLOT_RENDER_MODE.LANDSCAPE;
    }, [slotConfig])

    const writingMode = useMemo(() => {
        return slotContentOrientation === SLOT_RENDER_MODE.PORTRAIT ? 'vertical-rl' : 'horizontal-tb';
    }, [slotContentOrientation])

    const slotColor = useMemo(() => {
        const wrappedSlotPartIdx = isNumber(wrapCount) && wrapCount > 1 ? wrapRowIdx : undefined;
        return getColor(globalIdx || 0, wrappedSlotPartIdx)
    }, [])

  const slotLabel = useMemo(() => {
    return get(storeChannelsMap, `[${channelSlug}].title`, channelSlug);
  }, [storeChannelsMap, channelSlug])

    return (
        <div
            {...otherProps}
            ref={slotRef}
            className={styles.pixelMapChannelSlot}
            style={{
                width,
                height,
                background: slotColor,
            }}
        >
            <span
                className={styles.coordinatesLabel}
                style={{
                    writingMode,
                    left: isNumber(leftOffset) ? leftOffset : null,
                    fontSize: slotConfig.fontSizes.secondaryLabel
                }}
            >
                  ({slotConfig.x}px, {slotConfig.y}px)
              </span>

            <div
                className={styles.heightLabel}
                style={{
                    fontSize: slotConfig.fontSizes.secondaryLabel,
                    left: isNumber(leftOffset) ? leftOffset : null,
                    writingMode,
                }}
            >
                <span>Height:&nbsp;</span>
                <span className={styles.value}>{slotConfig.height}px</span>
            </div>


            <div
                className={styles.channelLabel}
                style={{
                    fontSize: slotConfig.fontSizes.mainLabel,
                    marginLeft: isNumber(leftOffset) ? leftOffset : null,
                    writingMode
                }}
            >
                {slotLabel} {shouldRenderWrapLabel ? `(${wrapRowIdx + 1}/${wrapCount})` : null}
            </div>

            <div
                className={styles.widthLabel}
                style={{
                    fontSize: slotConfig.fontSizes.secondaryLabel,
                    writingMode,
                }}
            >
                <span>Width:&nbsp;</span>
                <span className={styles.value}>{slotConfig.width}px</span>
            </div>
        </div>
    );
};

export default PixelMapChannelSlot;

@mixin primary-font {
    font-family: sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

@mixin secondary-font {
    font-family: monospace;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.pixelMapChannelSlot {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .coordinatesLabel {
        position: absolute;
        top: 0;
        left: 0;
        align-self: self-start;
        padding: 5px 0 0 5px;
        @include secondary-font;
    }

    .widthLabel {
        @include secondary-font;
        padding: 5px 5px 0 0;
        position: absolute;
        top: 0;
        right: 0;
    }

    .heightLabel {
        @include secondary-font;
        position: absolute;
        bottom: 0;
        padding: 0 0 5px 5px;
    }

    .channelLabel {
        @include primary-font;
        color: white;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }

    .value {
        font-weight: 600;
    }
}

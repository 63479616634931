import {ThemeConfig} from "../types";
import HoiTheme from "./hoi-theme";
import WellTheme from "./well-theme-grey";
import RiseLegacyTheme from "./rise-legacy";
import RiseTheme from "./rise-theme";
import NikeStyleTheme from "./nike-style-theme";
import Enum from '../../../../../utils/types'

const FAST_LANE2_THEME = {
    HOI: 'hoi',
    RISE: 'rise',
    RISE_LEGACY: 'rise-legacy',
    WELL: 'well',
    NIKE_STYLE: 'nike-style',
}
type FastLane2Theme = Enum<typeof FAST_LANE2_THEME>

export const themes: Record<FastLane2Theme, ThemeConfig> = {
    [FAST_LANE2_THEME.HOI]: HoiTheme,
    [FAST_LANE2_THEME.WELL]: WellTheme,
    [FAST_LANE2_THEME.RISE]: RiseTheme,
    [FAST_LANE2_THEME.RISE_LEGACY]: RiseLegacyTheme,
    [FAST_LANE2_THEME.NIKE_STYLE]: NikeStyleTheme,
}

export function getThemeFromString(
    theme: FastLane2Theme,
    defaultTheme: ThemeConfig = RiseTheme
): ThemeConfig {
    return themes?.[theme] ?? defaultTheme;
}
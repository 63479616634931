export function indexedColor(index) {
	return [
		'#75B36C',
		'#CFCEA4',
		'#F3D9AC',
		'#62A6BF',
		'#E49FB1',
		'#D5C4B9',
		'#CBA9DE',
		'#9C9EA4',
		'#7CD4BB',
	][index];
}

export const PIXEL_MAP_COLOR_PALETTE = [
	["#004646", "#005B5B", "#006F6F", "#007F7F", "#33A3A3"], // Cyan
	["#4C270B", "#5F2F0E", "#733710", "#8B4513", "#AA5C25"], // Brown
	["#750D37", "#990F45", "#AD144F", "#C2185B", "#DE4578"], // Pink
	["#3B0343", "#4C0455", "#5C0464", "#6A0572", "#8B2A91"], // Purple
	["#0E1A2B", "#152437", "#1C2F44", "#1D3557", "#3B5177"], // Blue
	["#004D4D", "#006666", "#007878", "#008B8B", "#33B5B5"], // Teal
	["#004B23", "#006400", "#007200", "#008000", "#38B000"], // Green
	["#9A801C", "#B39421", "#CCAA27", "#FFD23F", "#FFEA6A"], // Yellow
	["#9C361F", "#B84027", "#D34A2F", "#F46036", "#FF814F"], // Orange
	["#8B1926", "#A91F2E", "#C42535", "#D72638", "#E8454F"], // Red
];

export const getColor = (slotIdx, wrappedSlotPartIdx = 0) => {
	const mainIdx = slotIdx % PIXEL_MAP_COLOR_PALETTE.length;
	const secondaryIdx = wrappedSlotPartIdx % PIXEL_MAP_COLOR_PALETTE[0].length;
	return PIXEL_MAP_COLOR_PALETTE[mainIdx][secondaryIdx];
};

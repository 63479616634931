function SectionVerticalDivider() {
    return (
        <div
            style={{
                width: '1px',
                height: '100%',
                backgroundColor: 'white',
            }}
        />
    );
}

export default SectionVerticalDivider;
